import moment from "moment";

// Retorna campos permitidos com base no usuário
const getAllowedFields = (user, fields) => {
  if (user.in_uorgadm === 'T') { // Usuário gestor
    return [
      'periodManager',
      'businessArea',
      'shippingCompany',
      'commercialPartner',
      'contract',
      'ticket',
      'productGroup',
      'taxDocument',
      'vehicle',
      'invoice',
      'situation',
      'compliance',
      'dfe_key'
    ];
  }

  if (user.in_trp === 'T') { // Usuário transportador
    return [
      'periodShippingCompany',
      'businessArea',
      'commercialPartner',
      'contract',
      'ticket',
      'productGroup',
      'taxDocument',
      'vehicle',
      'invoice',
      'situation',
      'compliance',
      'dfe_key'
    ];
  }

  return [];
};

// Retorna os valores de datas com base no usuário
const getValues = (user) => ({
  startDate: moment().subtract(30, 'days').toDate(),
  endDate: moment().toDate(),
});

// Recupera as opções de filtro da API
const getOptions = async ({ api, startDate, endDate }) => {
  const { results = {} } = await api('controllership::list_filter_options', {
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate: moment(endDate).format('YYYY-MM-DD'),
  });

  return results;
};

// Cria itens de filtro com base nos parâmetros fornecidos
const createFilterItem = (filterData, { lang, options, values }) => {
  const [label, field, kind, settings] = filterData;
  const { startDate, endDate } = values;

  const item = {
    field,
    key: `${label}-${field}`,
    kind,
    label,
    options: [],
    settings,
  };

  switch (kind) {
    case 'daterange':
      item.value = { startDate, endDate };
      break;
    case 'input':
      item.value = values[field] ?? '';
      break;
    case 'multi-input':
      item.value = values[field] ?? [];
      break;
    case 'select':
    case 'multi-select':
      item.value = values[field];
      item.options = (options[field] ?? []).map(({ label: name, ...rest }) => ({
        ...rest,
        label: lang[name] ?? name,
      }));
      break;
    default:
      break;
  }

  return item;
};

const getFilters = ({ lang, user }) => {
  const fields = {
    periodManager: [lang.period, 'period', 'daterange', { limitation: 31 }],
    periodShippingCompany: [lang.period, 'period', 'daterange', { limitation: 7 }],
    businessArea: [lang.business_area, 'businessArea', 'select', { disabled: true }],
    shippingCompany: [lang.shipping_company, 'shippingCompany', 'select'],
    commercialPartner: [lang.commercial_partner, 'commercialPartner', 'select'],
    contract: [lang.contract, 'contracts', 'multi-input'], // input será tratado como InputNumberModalFilter
    invoice: [lang.invoice, 'invoices', 'multi-input'],
    ticket: [lang.ticket, 'tickets', 'multi-input'],
    productGroup: [lang.product_group, 'productGroup', 'select'],
    taxDocument: [lang.tax_document, 'taxDocuments', 'multi-input'],
    vehicle: [lang.vehicle, 'vehicles', 'multi-input'],
    situation: [lang.situation, 'situation', 'select'],
    compliance: [lang.compliance, 'compliance', 'select'],
    dfe_key: [lang.dfe_key, 'dfe_keys', 'multi-input'],
  };

  const allowedFields = getAllowedFields(user, fields);
  const values = getValues(user);

  return { fields, allowedFields, values };
};


// Função principal para obter os filtros, realizando consulta no backend
export const getFilterWithApiOptions = async ({ api, lang, user }) => {
  const { allowedFields, fields, values } = getFilters({ lang: lang.filters, user });
  const options = await getOptions({ api, ...values });

  return allowedFields
    .map(field => fields[field]) // Garante que fields[field] existe e é um array
    .filter(Boolean) // Remove valores undefined ou nulos
    .map(filterData => createFilterItem(filterData, { lang: lang.enums, options, values }));
};

// Função principal para obter os filtros, sem realizar a consulta no backend
export const getFilterWithoutApiOptions = ({ lang, user }) => {
  const { allowedFields, fields, values } = getFilters({ lang: lang.filters, user });

  return allowedFields
    .map(field => fields[field]) // Garante que fields[field] existe e é um array
    .filter(Boolean) // Remove valores undefined ou nulos
    .map(filterData => createFilterItem(filterData, { lang: lang.enums, options: {}, values }));
};

